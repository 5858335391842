/**
 * Button
 */

import React from 'react';
import classnames from 'classnames';
import css from './Button.module.css';

const Button = ({ children, className, element: Element, ...rest }) => (
  <Element className={classnames(css.root, className)} {...rest}>
    {children}
  </Element>
);

Button.defaultProps = {
  element: 'button',
};

export default Button;
