import React from 'react';
import classnames from 'classnames';
import css from './styles.module.css';

const Logo = ({ className, ...rest }) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 73 20"
      xmlSpace="preserve"
      className={classnames(css.logo, className)}
      {...rest}
    >
      <path d="M32.8 14.1c-.5 0-.9.2-1.2.6-1.1 1.3-2.7 2.2-4.5 2.2-1.3 0-2.5-.4-3.5-1.2-.8-.6-1.4-1.4-1.9-2.4-.3-.7-.5-1.5-.5-2.3 0-1.1.3-2.2.9-3.1.7-1.1 1.7-2 2.9-2.4.6-.2 1.3-.4 2-.4 1.3 0 2.4.4 3.4 1.1.4.3.8.7 1.1 1.1.3.4.7.6 1.2.6.3 0 .7-.1.9-.3.4-.3.6-.7.6-1.2 0-.4-.1-.7-.4-1-1.6-2-4.1-3.3-6.9-3.3-.9 0-1.9.2-2.9.6-1.8.7-3.4 2-4.4 3.6-.9 1.4-1.4 3-1.4 4.7 0 4.9 4 8.9 8.8 8.9 2.8 0 5.3-1.3 6.9-3.3.2-.3.3-.6.3-1 .1-.8-.6-1.5-1.4-1.5zm18.3 0c-.5 0-.9.2-1.2.6-1.1 1.3-2.7 2.2-4.5 2.2-1.3 0-2.5-.4-3.5-1.2-.8-.6-1.4-1.4-1.9-2.4-.3-.7-.5-1.5-.5-2.3 0-1.1.3-2.2.9-3.1.7-1.1 1.7-2 2.9-2.4.6-.2 1.3-.4 2-.4 1.3 0 2.4.4 3.4 1.1.4.3.8.7 1.1 1.1.3.4.7.6 1.2.6.3 0 .7-.1.9-.3.4-.3.6-.7.6-1.2 0-.4-.1-.7-.3-1-1.6-2-4.1-3.3-6.9-3.3-1.1 0-2.1.2-3.1.6-1.8.7-3.4 2-4.4 3.6-.9 1.4-1.4 3-1.4 4.8 0 4.9 4 8.9 8.8 8.9 2.8 0 5.3-1.3 6.9-3.3.2-.3.3-.6.3-1 .2-.9-.5-1.6-1.3-1.6zm18.8-8.5c-.8 0-1.5.7-1.5 1.5 0 .3.1.6.3.9.6.9.9 1.9.9 3.1 0 .8-.2 1.6-.5 2.3-.4 1-1 1.8-1.8 2.4-1 .7-2.2 1.2-3.5 1.2-1.8 0-3.5-.9-4.5-2.2-.8-1-1.3-2.3-1.3-3.7 0-2 1-3.7 2.4-4.8.9-.7 2.1-1.1 3.4-1.1.7 0 1.4.1 2 .4.2.1.3.1.5.1.8 0 1.5-.7 1.5-1.5 0-.7-.4-1.2-1-1.4-1-.4-2-.6-3-.6-2.8 0-5.2 1.3-6.9 3.3-1.2 1.5-2 3.5-2 5.6 0 2.1.7 4.1 2 5.6 1.6 2 4.1 3.3 6.9 3.3 4.9 0 8.8-4 8.8-8.9 0-1.7-.5-3.4-1.3-4.7-.4-.5-.9-.8-1.4-.8zm-55.4 8.5c-.5 0-.9.2-1.2.6-1.1 1.3-2.7 2.2-4.5 2.2-1.3 0-2.5-.4-3.5-1.2l10.1-8.1c.4-.3.6-.7.6-1.2 0-.4-.1-.7-.4-1-1.6-2-4.1-3.3-6.9-3.3-1.1 0-2.1.2-3.1.6-.6.2-1 .8-1 1.4 0 .9.7 1.5 1.5 1.5.2 0 .4 0 .5-.1.6-.2 1.3-.4 2-.4 1.3 0 2.4.4 3.4 1.1l-8.7 7.1c-.1-.6-.3-1.4-.3-2.2 0-1.2.4-2.2.9-3.1.1-.2.3-.6.3-.9 0-.8-.7-1.5-1.5-1.5-.5 0-1 .3-1.3.7C.5 7.7 0 9.3 0 11.1 0 16 4 20 8.8 20c2.8 0 5.3-1.3 6.9-3.3.2-.3.3-.6.3-1 .1-.9-.6-1.6-1.5-1.6zM71 3.8c1 0 1.9-.9 1.9-1.9 0-1-.8-1.9-1.9-1.9-1 0-1.9.9-1.9 1.9.1 1.1.9 1.9 1.9 1.9zm0-3.4c.8 0 1.5.7 1.5 1.5 0 .9-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5S70.2.4 71 .4z"/>
      <path d="M70.8 2.1h.1c.2 0 .2.1.3.2l.2.6h.4l-.3-.7c0-.1-.1-.2-.2-.2.2 0 .4-.2.4-.4 0-.6-.5-.6-.9-.6h-.4v1.9h.4v-.8zm0-.8h.1c.2 0 .4 0 .4.2s-.2.3-.4.3h-.1v-.5z"/>
    </svg>
);

export default Logo;
